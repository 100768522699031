/*
 * @Author: lbh
 * @Date: 2023-07-26 10:30:17
 * @LastEditors: lbh
 * @LastEditTime: 2023-08-18 10:43:24
 * @Description: file content
 */
// src/utils下创建i18n/index.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';

// 引入各个语言配置文件
import zh from './zh.js';
import hk from './hk.js';

let lang = 'zh';
const href = location.href;

if (href.includes('ricepon.cn') || href.includes('ricepon-cn')) {
  let l = localStorage.getItem('ricepon.cn.i18n');
  lang = l || 'zh';
} else if (href.includes('ricepon.com') || href.includes('ricepon-main'))
  lang = 'hk';

Vue.use(VueI18n);
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  locale: lang, // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    zh,
    hk,
  },
});
// 暴露i18n
export default i18n;
