/*
 * @Author: lbh
 * @Date: 2023-07-26 10:30:32
 * @LastEditors: lbh
 * @LastEditTime: 2024-02-29 14:51:00
 * @Description: file content
 */
const hk = {
  test: '測試',
  backTop: '回到頂部',
  companyName: '飯糰有限公司',
  company: {
    ricepon: '飯糰有限公司',
    riceponCn: '深圳市夠姜網絡科技有限公司',
  },
  bannerImg: '1690532302877150a4ba8Ricepon.png',
  download: {
    title: '下載飯糰app',
    label: '免費獲取最新餐飲資訊',
    href: '立刻下載',
  },
  header: {
    ricepon: 'Ricepon飯糰',
    contactUs: '聯絡我們',
    orderaMeal: '點餐',
    homePage: '首頁',
    aboutGingersoft: '關於Ricepon',
    product: '產品',
    DeliverySystem: '外賣系統',
    CateringSystem: '餐飲系統',
    GSPOSOrderSystem: 'POS落單系統',
    GSPOSCateringManagementSystem: 'GSPOS餐飲管理系統',
    GSAMobileOrderingSystem: 'GSA 手機點餐系統',
    CloudBasedCateringManagementSystem: '雲端餐飲管理系統',
    GSQSelfServiceQueuingSystem: 'GSQ 自助排隊系統',
    ricepon_: '飯糰',
    QrCodeOrdering: 'QR code點餐',
    MembershipSystem: '會員系統',
    'Delivery-SelfPickup': '外賣/自取',
    'Online booking': '網上訂座',
    'Queue up to collect tickets': '排隊取票',
    'Customer Stories': '客戶案例',
    'Beifu Group': '別府集團',
    'Daikiya Group': '大喜屋集團',
    'Huang Zhenzhen Thai Restaurant': '黃珍珍泰國菜館',
    'Our customers': '我們的客戶',
    'Free trial': '免費試用',
  },
  footer: {
    'Company Information': '公司信息',
    'Company Name': '飯糰有限公司 Ricepon Limited',
    'Contact number (Hong Kong)': '聯繫電話(香港)',
    mailbox: '郵箱',
    address: {
      label: '單位地址',
      value: '九龍長沙灣永康街51-53號時穎中心9樓903室',
      ricepon: '九龍長沙灣永康街51-53號時穎中心9樓903室',
      riceponCn: '深圳市龍華區民治街道融創智匯大廈C座1922室',
    },
    'Our social platform': '我們的社交平台',
    'Corporate Business': '公司業務',
    'GSPOS order placement system': 'POS落單系統',
    'Mobile ordering system': '手機點餐系統',
    'Self pickup queuing system': '自助排隊系統',
    'Online booking': '網上訂座',
    Delivery: '外賣系統',
    'Scan Order': '掃碼點餐',
    'Queuing for number retrieval': '排隊取號',
    MembershipSystem: '會員系統',
    'Games and Marketing': '遊戲及營銷',
    partner: '合作夥伴',
    Octopus: '八達通',
    FilingNumber: {
      label: 'CopyRight 2022-2040 深圳市姜科網絡科技有限公司 备案号',
      value: '粤ICP备2022075036号-1',
    },
  },
  // app介紹
  introduce: {
    ricepon: {
      label: 'RICEPON飯糰',
      value: '多種點餐方式，讓您生活更方便省時',
    },
    memberCard: {
      label: '會員卡',
      value: '多間餐廳會員卡添加，簡單方便一站式',
    },
    MemberBenefits: {
      label: '會員福利',
      value: '消費儲分，兌換積分，會員優惠劵',
    },
    coupon: {
      label: '優惠劵',
      value: '迎新優惠，升級優惠，生日優惠。會員優惠停不了',
    },
  },
  // 餐廳案列
  restaurantCase: {
    bigTitle: '餐廳常遇到的問題及解決案例',
    'Solving Cases': '解決案例',
    'Further understanding': '進一步了解',
    one: {
      label: '人手不足，成本每季增加',
      value:
        '客人利用QR Code點餐系統令餐廳節省接近8-12%待應人手，加上某些餐廳更大大節省了點餐紙的印刷成本。',
    },
    two: {
      label: '想與顧客關係更貼近',
      value:
        'APP會員系統，會員可以儲分換優惠，餐廳也可以推送優惠劵給顧客，與顧客更多互動及更貼近',
    },
    three: {
      label: '溝通問題，經常落錯單',
      value:
        '顧客透過QR code自助點單，減少顧客與待應溝通出錯的機會，另外POS系統支援多國語言，減低廚房出錯問題',
    },
  },
  products: {
    LearnMore: '了解更多',
    QRCodeOrdering: {
      label: 'QR code點餐',
      value:
        '掃碼點餐、固定二維碼點餐、預點餐，多種點餐管道，有效提高點餐效率，减少顧客排隊的時間，提高了顧客的用餐體驗',
    },
    MembershipSystem: {
      label: '會員系統',
      value:
        '線上發佈會員卡，方便顧客領取和儲存，發展長期，穩定，忠誠的消費群體，擴大市場佔有率，新增客戶粘性',
    },
    DeliverySelfPickup: {
      label: '外賣/自取',
      value:
        '線上訂餐，方便快捷，支持APP線上訂餐，顧客可以在家，在公司點餐，餐廳自行配送到顧客所在的位置',
    },
    OnlineBooking: {
      label: '網上訂座',
      value: '光速訂座APP預訂座位，準確記錄顧客預訂的需求，讓服務零距離接觸',
    },
    QueueUpToCollectTickets: {
      label: '排隊取票',
      value:
        '遠程取號無需等位，顧客用APP在任何地方遠程取號，自動提醒顧客準備入場',
    },
  },
  // 聯絡我們
  contact: {
    bigTitle: '聯絡我們 – 飯糰有限公司',
    'Dietary literature, published on Ricepon': '搵飲食著數，上Ricepon',
    'Contact Us': '聯絡我們',
    'Search for different food and beverage discounts for you':
      '為你搜盡不同餐飲優惠',
    'Search for the latest restaurant information for you':
      '為你搜羅最新餐廳資訊',
    'Most Collected Restaurant Offers': '集齊最多餐廳優惠',
    'Scan Code Download': '掃碼下載',
    'Join the ricepon': '加入飯糰',
    'Please enter your name': '請輸入您的姓名',
    'Please enter your contact number': '請輸入您的聯絡電話',
    'Please enter the correct phone number': '請輸入正確的電話號碼',
    'Please enter your restaurant name': '請輸入您的餐廳名稱',
    'Submit application': '提交申請',
    'Leave information': '請留底你嘅資料，我哋好快會有專人與你聯絡',
    'full name': '姓名',
    Telephone: '聯絡電話',
    'Restaurant Name': '餐廳名稱',
    Notes: '備註',
    'Please enter the information you need to make a note of':
      '請輸入您需要備注的信息',
    Cancel: '取消',
    Confirm: '提交',
    Thank: '謝謝,已發送您的資訊',
    Error: '發生錯誤,請檢查您的資料信息',
  },
  // 产品页
  productsPage: {
    title: '產品 – 飯糰有限公司',
    bigTitle: '產品',
    btn: '了解產品',
    labelA: {
      title: 'GSPOS 點餐系統',
      desc: '集合多功能於一身，簡易操作介面，助你輕鬆管理你的餐飲業',
    },
    labelB: {
      title: 'GSA 手機點餐系統',
      desc: '隨身的移動點餐系統，快捷，方便，準確',
    },
    labelC: {
      title: 'GSQ 自助排隊系統',
      desc: '自助取票，廣告，叫號，一體化介面，輕鬆提高餐廳形象',
    },
  },
  case: {
    title: '客戶案例 – 飯糰有限公司',
    bigTitle: '客戶案例',
    titleA: '熱門案例',
    titleB: '我們的客戶',
    descA: '飲食集團',
    descB: '泰國菜',
    labelA: '別府飲食集團',
    labelB: '大喜屋',
    labelC: '黄珍珍泰國菜館',
  },
  // GSPOS 落單系統
  gspos: {
    bigTitle: 'GSPOS 落單系統',
    labelA: {
      title: 'GSPOS 點餐系統功能特點',
      itemA: '多國語言廚房打印',
      itemB: '多國語言餐牌顯示',
      itemC: '多重廚房打印設定',
      itemD: '食品同時打印至多台打印機',
      itemF: '廚房飛單功能',
      itemG: '同時多機聯網操作',
      itemH: '套餐,食品,細項關係挷定',
      itemI: '時段及假日餐牌設定',
      itemJ: '時段及假日折扣設定',
      itemK: '食品同時打印至多台打印機',
      itemM: '打印總單',
      itemN: '中央餐牌管理',
      itemO: '每日清機結算提醒, 及排程自動清機結算',
      itemQ: '自定分類報表',
      itemR: '即時報表',
      itemS: '過去報表及統計分析報表',
    },
    labelB: {
      title: '提供規劃建議',
      itemA:
        '因應餐廳需要，為你的餐廳提供合適的方案，例如: 提供整套系統的網線路線規劃建議，到餐廳實地視察後與裝修師傅商議拉線規劃事宜等等。',
    },
    labelC: {
      title: '中央打印管理',
      itemA:
        '以中央Print Server管理所有打印機的列印工作，不同的食品或飲品可分類到不同的部門位置列印。',
    },
    labelD: {
      title: '後備打印方案',
      itemA:
        '預先設定打印機的後備打印方案，當打印機發生故障、或特定時間內沒有入紙等，系統會自動將有關單據轉去後備打印機的位置列印，我們稱為 “飛單”，令餐廳可如常運作。',
    },
  },
  // GSA 手機點餐系統
  gsa: {
    bigTitle: 'GSA 手機點餐系統',
    labelA: {
      title: 'GSA 手機點餐系統功能特點',
      itemA: '簡易操作, 容易上手',
      itemB: '支援多國語言',
      itemC: '餐牌更新提醒',
      itemD: '支持印單, 打印上菜紙等功能',
      itemE: '簡單的滑動操作，使屏幕可顯示最多的內容',
      itemF: '點餐介面排版靈活設定',
      itemG: '轉檯, 連檯, 分檯',
      itemH: '系統一鍵升級',
    },
    labelB: {
      title: '直接落單點餐',
      itemA:
        '當你為客人點餐時， 若配備了GSA手機點餐系統， 員工便可直接使用手機為客人點餐， 不用 “寫紙頭”， 更不需要每次都前往POS系統主機進行點餐程序，省時方便，有效率地完成點餐程序，增加翻枱次數。',
    },
    labelC: {
      title: '減低出錯機會',
      itemA:
        '傳統 “寫紙頭” 點餐，由於很多時由樓面員工負責 “寫紙頭” 點餐，再交由特定員工於POS點餐，常常會遇到看不清楚紙頭上寫的是甚麼字，或樓面員工寫錯單，增加不少出錯機會，而使用手機系統點餐，可即時從手機上看到點餐的列表，還可以在客人點餐完成後，即時向客人複述點餐內容，將出錯率減到最低。',
    },
  },
  // GSQ 自助排隊系統
  gsq: {
    bigTitle: 'GSQ 自助排隊系統',
    labelA: {
      title: '自助取票端',
      itemA: '客人自助輸入人數取票',
      itemB: '系統自動分隊',
      itemC: '入座SMS短訊提示功能',
      itemD: '自定SMS短訊發送間距',
      itemE: '輸入特定以上人數特別提示',
      itemF: '鎖屏功能（防止客人在非營業時間內取票）',
    },
    labelB: {
      title: '號碼 / 廣告顯示端',
      itemA: '介面排版靈活',
      itemB: '支援多個廣告面板',
      itemC: '列隊模式, 多號模式',
      itemD: '支援多屏不同排版',
      itemE: '支援圖片, 影片, 文字播放',
      itemF: '獨立設置不同時段及假期的廣告播放',
    },
    labelC: {
      title: '叫號端',
      itemA: '簡潔介面, 操作簡單',
      itemB: '支援員工取票功能',
      itemC: '系統自動選取叫號號碼, 一鍵叫號',
      itemD: '提供入座數據報表',
    },
  },
  //
  about: {
    bigTitle: 'Ricepon 飯糰',
    title: '關於Ricepon',
    labelA: {
      title: '公司簡介',
      itemA:
        'Ricepon 飯糰 在香港成立於2012年，一直專注於餐飲業營運系統上，積極研發及推廣全面的餐飲系統軟件，致力為客戶提供優質的產品與服務，針對客戶不同的需要，提供最合適的方案。',
      itemB:
        '我們在深圳擁有自己的開發團隊，年輕的成員們都充滿著工作熱誠，亦十分重視團隊的合作精神，用心創造每一個可能。',
      itemBRiceponCn:
        '我們在深圳成立了够薑網絡科技有限公司，擁有自己的開發團隊，年輕的成員們都充滿著工作熱誠，亦十分重視團隊的合作精神，用心創造每一個可能。',
    },
    labelB: {
      title: '經營理念',
      itemA:
        '餐飲業面對租金高, 人工高, 食材高, 利潤低的3高1低痛點, 戰場方面更由實體店延伸到線上 戰場, 餐廳營運者更需要在激烈的戰局中提升競爭力，為解決市場痛點, 我們Ricepon 的 產品設計師多年研發線上線下解決方案, 運用線上系統與線下系統無縫對接, 系統一體化令用 戶與餐廳用最簡單的方式溝通, 系統智能推薦令餐廳人員能夠提前了解客人需要, 作出針對性 銷售, 亦可分析餐廳營運佈局, 作出餐廳資源共享, 令進一步提升產能!',
    },
    labelC: {
      itemA: '系統碎片化 vs 系統一體化',
      itemB: '線上線下結合的用戶體驗',
      itemC: '功能服務鏈一脈相連',
      itemD: '智慧系統智能推薦',
    },
    labelD: {
      title: 'Ricepon格言',
      desc: '用研發人員的複雜，成就用戶的簡單！',
    },
  },
  qrcode: {
    bigTitle: 'QR Code點餐',
    desc: '省時方便，減低落錯單機會',
    labelA: {
      title: '',
      itemA: 'APP – 支援免註冊點餐',
      itemB: 'APP – 掃一下馬上點餐',
      itemC: 'APP – 支援一桌多機',
      itemD: 'APP – 離座資訊提醒',
      itemE: 'APP – QR指定餐牌',
      itemF: 'APP – 食品數量上限控制',
      itemG: 'POS – 實時沽清控制',
      itemH: 'POS – 時段列印分流',
      itemI: 'POS – 食品項目彙合列印 廚房單 – 頭單提醒',
      itemJ: '廚房單 – Last order 提醒 QR Code 一碼多用',
      itemK: '(下載APP/點菜/加入會員卡) 餐牌公告提醒',
    },
  },
  vip: {
    bigTitle: '會員系統',
    desc: '增強主動營銷能力，與顧客更多互動',
    labelA: {
      itemA:
        '線上發佈會員卡，方便顧客領取和儲存，發展長期，穩定，忠誠的消費群體，擴大市場佔有率，新增客戶黏性',
    },
    labelB: {
      title: '餐廳最新信息',
      itemA: '自由編輯宣傳內容',
      itemB: '外部連結',
      itemC: '播放短片',
      itemD: '首頁限時免費廣告',
    },
    labelC: {
      title: '自訂投票活動',
      itemA: '品牌自定義投票',
      itemB: '分店自定義投票',
      itemC: '單項投票',
      itemD: '順位投票',
    },
    labelD: { title: '餐廳評論', itemA: '訂單評論', itemB: '菜式評論' },
  },
  takeOutOrPickUp: {
    bigTitle: '外賣/自取',
    desc: '先落單後取餐，省時方便',
    labelA: {
      title: '外賣',
      itemA: '線上訂餐，方便快捷支援',
      itemB: 'APP線上訂餐',
      itemC: '顧客可以在家，在公司點餐',
      itemD: '餐廳自行配送到顧客所在的位置',
    },
  },
  reservation: {
    bigTitle: '網上訂座',
    desc: 'Ricepon App帶來全新的體驗',
    labelA: {
      title: '',
      itemA: '光速訂座APP預訂座位，準確記錄顧客預訂的需求，讓服務零距離接觸',
    },
    labelB: { title: '訂座入口', itemA: '餐廳官網' },
    labelC: {
      title: 'Ipad系統功能特點',
      itemA: '坐位圖及顧客信息，一覽無遺',
      itemB: '統自動SMS確認訂位',
      itemC: '時段訂位人數限制，控位一手掌握',
      itemD: '支持手機版訂座管理',
      itemE: '直接傳送客人資料到線下POS',
    },
  },
  lineUp: {
    bigTitle: '排隊取票',
    desc: '自助取票方便快捷',
    labelA: {
      title: '',
      itemA: '遠程取號無需等位',
      itemB: '支援APP遠程取號',
      itemC: '顧客可以在家、在公司等任何地方排隊取號自動提醒顧客準備入場',
    },
  },
  biefu: {
    bigTitle: '熱門案例',
    title: '別府飲食集團',
    labelA: {
      title: '餐廳面對問題',
      itemA: '人手填寫會員資料, 實體卡損耗及缺乏主動營銷渠道',
    },
    labelB: {
      title: '解決方案',
      itemA: '產品名稱: 別府集團APP',
      itemB: '使用別府集團手機APP, SMS驗證, 會員簡易註冊, 與POS對接',
      itemC: '消費, 儲經驗值, 升級, 派優惠券, 儲分, 無紙化, 以及二次營銷',
    },
  },
  daxiwu: {
    bigTitle: '熱門案例',
    title: '大喜屋集團',
    labelA: {
      title: '餐廳面對問題',
      itemA: '人手不足, 經常落錯單, 放題紙印刷成本高昂',
    },
    labelB: {
      title: '解決方案',
      itemA: '產品名稱: RICEPON SKYORDER服務',
      itemB: '項目年份: 2017',
      itemC: '顧客使用手機APP落單, 節省員工落單時間, 縮短出餐時間',
      itemD: '減低出錯, 環保同時更省卻放題紙高昂的成本',
    },
  },
  huangzhengzheng: {
    bigTitle: '熱門案例',
    title: '黄珍珍泰國菜館',
    labelA: {
      title: '餐廳面對問題',
      itemA: '沒有自己的會員系統， 不能直接給顧客送贈禮物及優惠券',
      itemB: '泰國廚師經常落錯單',
    },
    labelB: {
      title: '解決方案',
      itemA: '產品名稱: POS + Mobile POS',
      itemB: '項目年份: 2018',
      itemC: '店員用Mobile POS落單, 節省員工落單時間, 減低出錯',
      itemD: '廚房同時打印泰文及中文, 方便泰國廚師及樓面人員溝通',
    },
  },
};
export default hk;
