<!--
 * @Author: lbh
 * @Date: 2022-03-10 16:49:43
 * @LastEditors: lbh
 * @LastEditTime: 2022-07-26 17:31:19
 * @Description: 聯絡我們 全局
-->
<template>
  <div class="contact-box">
    <!-- 按鈕 -->
    <div
      class="fixed"
      @click="showMark"
    >
      聯絡我們
      <!-- <img src="../../assets/default/Vectorphone.png" /> -->
    </div>
    <img
      @click="showMark"
      src="../../assets/default/WhatsApp.png"
      class="fixed_top"
    />
    <!-- 彈窗 -->
    <el-dialog
      title=""
      class="mark-box"
      width="auto"
      :visible.sync="showBox"
    >
      <!-- :rules="rules" -->
      <el-form
        :model="form"
        ref="ruleForm"
        :rules="rules"
        class="box"
        v-loading="btnLoading"
      >
        <div class="title">Ricepon報價或預約演示</div>
        <!-- content -->

        <div class="content">
          <div class="cell-box">
            <div class="cell">
              <div class="t">姓名 <span>*</span></div>
              <el-form-item prop="name">
                <el-input
                  v-model="form.name"
                  :class="rules_.name ? '' : 'error'"
                  placeholder="請輸入您的姓名"
                ></el-input>
              </el-form-item>
            </div>
            <div class="cell">
              <div class="t">聯絡方式（電話）<span>*</span></div>
              <el-form-item prop="phone">
                <el-input
                  v-model="form.phone"
                  type="tel"
                  :class="rules_.phone ? '' : 'error'"
                  maxlength="8"
                  placeholder="請輸入您的聯繫方式"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="cell">
            <div class="t">餐廳名稱 <span>*</span></div>
            <el-form-item prop="restaurantName">
              <el-input
                v-model="form.restaurantName"
                :class="rules_.restaurantName ? '' : 'error'"
                placeholder="請輸入您的餐廳名稱"
              ></el-input>
            </el-form-item>
          </div>
          <div class="cell cell-last">
            <div class="t">備註</div>
            <el-form-item prop="description">
              <el-input
                v-model="form.description"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 4}"
                placeholder="請輸入您的備註"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="btn-box">
          <el-button @click="showBox = false">取消</el-button>
          <el-button
            @click="submitForm"
            type="primary"
          >提交</el-button>
        </div>
      </el-form>
      <div
        v-show="submitFlag"
        class="success"
      >
        <div class="content">
          <img src="../../assets/default/animat-checkmark.gif" />
          <div class="title">已收到您的留言，我們將盡快聯繫您！</div>
        </div>
        <div class="btn-box">
          <el-button @click="showBox = false">關閉</el-button>
          <el-button
            @click="showBox = false"
            type="primary"
          >好的</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addHomeUser } from "@/js/api/api";
export default {
  name: "self-contact",
  data () {
    return {
      showBox: false,
      btnLoading: false,
      form: {
        name: '',
        phone: '',
        emailAddress: '',
        restaurantName: '',
        restaurantType: '1',
        description: '',
        source: 1
      },
      rules_: {
        name: true,
        restaurantName: true,
        phone: true
      },
      rules: {
        name_: [
          { required: true, message: '請輸入名稱', trigger: 'blur' },
        ],
        phone: [
          // { required: true, message: '請輸入聯絡方式', trigger: 'blur' },
          { min: 8, max: 8, message: '請輸入正確的聯絡方式', trigger: 'blur' }
        ],
        restaurantName_: [
          { required: true, message: '請輸入餐廳名稱', trigger: 'blur' },
        ],
      },
      submitFlag: false
    }
  },
  methods: {
    showMark () {
      this.form = {
        name: '',
        phone: '',
        emailAddress: '',
        restaurantName: '',
        restaurantType: '1',
        description: ''
      };
      this.rules_.name = true;
      this.rules_.restaurantName = true;
      this.rules_.phone = true;
      this.submitFlag = false;
      this.showBox = true
    },
    submitForm () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let form = this.form;
          if (!form.name) {
            this.rules_.name = false;
            return;
          } else if (!form.phone) {
            this.rules_.phone = false;
          } else if (!form.restaurantName) {
            this.rules_.restaurantName = false;
          } else {
            this.addHomeUserFun()
          }
        } else {
          return false;
        }
      })
    },
    // 提交信息
    addHomeUserFun () {
      this.btnLoading = true;
      addHomeUser(this.form).then(res => {
        this.btnLoading = false;
        if (res.success) {
          // this.showBox = false;
          this.submitFlag = true;
          this.$message({
            message: '謝謝,已發送您的資訊',
            type: 'success'
          });
        } else {
          this.$message.error('發生錯誤,請檢查您的資料信息');
        }
      }).catch(err => { this.btnLoading = false; })
    },
  }

}
</script>

<style lang="less" scoped>
.contact-box {
  .fixed {
    transition: 1s;
    position: fixed;
    width: 160px;
    height: 70px;
    background-color: #be1c42;
    color: #fff;
    right: 24px;
    bottom: 40px;
    border-radius: 35px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 16px 2px rgba(190, 28, 66, 0.4);
    z-index: 1000;
    img {
      width: 22px;
      height: 22px;
    }
  }
  .fixed_top {
    display: none;
  }
  .mark-box {
    position: fixed;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.3);
    & /deep/ .el-dialog__headerbtn {
      font-size: 24px;
      top: 24px;
      right: 24px;
    }
    & /deep/ .el-dialog__body {
      padding: 30px 24px 0 24px;
      position: relative;
    }
    & /deep/ .el-loading-mask {
      .el-loading-spinner {
        .circular {
          .path {
            stroke: #be1c42;
          }
        }
      }
    }
    .box {
      background: #ffffff;
      // box-shadow: 0px 8px 24px rgba(96, 98, 102, 0.2);
      border-radius: 2px;
      padding: 0 0 32px 0;
      .title {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: #18181a;
        text-align: center;
      }
      .content {
        padding: 60px 76px 60px 76px;
        .cell-box {
          display: flex;
          .cell {
            &:nth-child(2) {
              margin-left: 60px;
            }
          }
        }
        .cell {
          .t {
            font-family: "Source Han Sans CN";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            text-transform: capitalize;
            color: #606266;
            margin-bottom: 16px;
            span {
              color: red;
            }
          }
          & /deep/ .el-form-item {
            margin-bottom: 24px;
          }
          & /deep/ input {
            &::-webkit-input-placeholder {
              font-family: "Source Han Sans CN";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #c0c4cc;
            }
            &::-moz-placeholder {
              font-family: "Source Han Sans CN";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: rgb(120, 127, 139);
            }
            &:-moz-placeholder {
              font-family: "Source Han Sans CN";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #c0c4cc;
            }
            &:-ms-input-placeholder {
              font-family: "Source Han Sans CN";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #c0c4cc;
            }
          }
          & /deep/ .el-input {
            .el-input__inner {
              border-width: 0;
              border-radius: 0;
              border-bottom: 1px solid #c0c4cc;
              padding-left: 0;
              height: 32px;
              line-height: 32px;
            }
            &.error {
              .el-input__inner {
                &::-webkit-input-placeholder {
                  color: red !important;
                }
                &::-moz-placeholder {
                  color: red !important;
                }
                &:-ms-input-placeholder {
                  color: red !important;
                }
                &:-ms-input-placeholder {
                  color: red !important;
                }
              }
            }
          }
          & /deep/ .el-form-item__error {
            top: -14px;
          }
          & /deep/ .el-textarea {
            .el-textarea__inner {
              border-width: 0;
              border-radius: 0;
              border-bottom: 1px solid #c0c4cc;
              padding-left: 0;
              &::-webkit-input-placeholder {
                font-family: "Source Han Sans CN";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #c0c4cc;
              }
              &::-moz-placeholder {
                font-family: "Source Han Sans CN";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #c0c4cc;
              }
              &:-moz-placeholder {
                font-family: "Source Han Sans CN";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #c0c4cc;
              }
              &:-ms-input-placeholder {
                font-family: "Source Han Sans CN";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #c0c4cc;
              }
            }
          }
        }
        .cell-last {
          & /deep/ .el-form-item {
            margin-bottom: 0;
          }
        }
      }
      .btn-box {
        display: flex;
        & /deep/ .el-button {
          padding: 12px 24px;
          &:nth-child(1) {
            margin-left: auto;
            color: #be1c42;
            border-color: #be1c42;
            background-color: #fff;
            &:focus,
            &:hover {
              background-color: rgba(191, 29, 66, 0.2);
            }
          }
          &:nth-child(2) {
            margin-left: 24px;
            border-color: #be1c42;
            background: #be1c42;
            &:focus,
            &:hover {
              background-color: rgba(191, 29, 66, 0.9);
            }
          }
        }
      }
    }
    .success {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      width: 100%;
      height: 100%;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        img {
          width: 120px;
          height: 120px;
        }
        .title {
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #606266;
        }
      }
      .btn-box {
        position: absolute;
        bottom: 32px;
        right: 24px;
        display: flex;
        & /deep/ .el-button {
          padding: 12px 24px;
          &:nth-child(1) {
            margin-left: auto;
            color: #be1c42;
            border-color: #be1c42;
            background-color: #fff;
            &:focus,
            &:hover {
              background-color: rgba(191, 29, 66, 0.2);
            }
          }
          &:nth-child(2) {
            margin-left: 24px;
            border-color: #be1c42;
            background: #be1c42;
            &:focus,
            &:hover {
              background-color: rgba(191, 29, 66, 0.9);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .contact-box {
    .fixed {
      display: none;
    }
    .fixed_top {
      display: block;
      width: 60px;
      transition: 1s;
      position: fixed;
      right: 24px;
      bottom: 40px;
    }
    .mark-box {
      & /deep/ .el-dialog {
        width: calc(100% - 30px) !important;
      }
      & /deep/ .el-dialog__body {
        padding: 0;
      }
      .box {
        padding: 0;
        .content {
          padding: 30px;
          .cell-box {
            flex-direction: column;
            .cell {
              &:nth-child(2) {
                margin-left: 0;
              }
            }
          }
        }
        .btn-box {
          padding: 0 20px 20px;
        }
      }
    }
  }
}
</style>