/*
 * @Author: lbh
 * @Date: 2023-07-26 10:30:28
 * @LastEditors: lbh
 * @LastEditTime: 2024-02-29 14:51:29
 * @Description: file content
 */
const zh = {
  test: '测试',
  backTop: '回到顶部',
  companyName: '深圳市够姜网络科技有限公司',
  company: {
    ricepon: '姜软件有限公司',
    riceponCn: '深圳市够姜网络科技有限公司',
  },
  bannerImg: '1690532461294fdfa1507Ricepon.png',
  download: {
    title: '下载饭团app',
    label: '免费获取最新餐饮信息',
    href: '立刻下载',
  },
  header: {
    ricepon: 'Ricepon饭团',
    contactUs: '联络我们',
    orderaMeal: '点餐',
    homePage: '首页',
    aboutGingersoft: '关于Ricepon',
    product: '产品',
    DeliverySystem: '外卖系统',
    CateringSystem: '餐饮系统',
    GSPOSOrderSystem: 'POS落单系统',
    GSPOSCateringManagementSystem: 'GSPOS餐饮管理系统',
    GSAMobileOrderingSystem: 'GSA 手机点餐系统',
    CloudBasedCateringManagementSystem: '云端餐饮管理系统',
    GSQSelfServiceQueuingSystem: 'GSQ 自助排队系统',
    ricepon_: '饭团',
    QrCodeOrdering: 'QR code点餐',
    MembershipSystem: '会员系统',
    'Delivery-SelfPickup': '外卖/自取',
    'Online booking': '网上订座',
    'Queue up to collect tickets': '排队取票',
    'Customer Stories': '客户案例',
    'Beifu Group': '别府集团',
    'Daikiya Group': '大喜屋集团',
    'Huang Zhenzhen Thai Restaurant': '黄珍珍泰国菜馆',
    'Our customers': '我们的客户',
    'Free trial': '免费试用',
  },
  footer: {
    'Company Information': '公司信息',
    'Company Name': '深圳市够姜网络科技有限公司',
    'Contact number (Hong Kong)': '联系电话(香港)',
    mailbox: '邮箱',
    address: {
      label: '单位地址',
      value: '深圳市龙华区民治街道融创智汇大厦C座1922室',
      ricepon: '香港九龙永康街51 53号时颖中心1401 02室',
      riceponCn: '深圳市龙华区民治街道融创智汇大厦C座1922室',
    },
    'Our social platform': '我们的社交平台',
    'Corporate Business': '公司业务',
    'GSPOS order placement system': 'POS落单系统',
    'Mobile ordering system': '手机点餐系统',
    'Self pickup queuing system': '自助排队系统',
    'Online booking': '网上订座',
    Delivery: '外卖系统',
    'Scan Order': '扫码点餐',
    'Queuing for number retrieval': '排队取号',
    MembershipSystem: '会员系统',
    'Games and Marketing': '游戏及营销',
    partner: '合作伙伴',
    Octopus: '八达通',
    FilingNumber: {
      label: 'CopyRight 2022-2040 深圳市姜科网络科技有限公司 备案号',
      value: '粤ICP备2022075036号-1',
    },
  },
  // app介紹
  introduce: {
    ricepon: {
      label: 'RICEPON饭团',
      value: '多种点餐方式，让您生活更方便省时',
    },
    memberCard: {
      label: '会员卡',
      value: '多间餐厅会员卡添加，简单方便一站式',
    },
    MemberBenefits: {
      label: '会员福利',
      value: '消费储分，兑换积分，会员优惠劵',
    },
    coupon: {
      label: '优惠劵',
      value: '迎新优惠，升级优惠，生日优惠。会员优惠停不了',
    },
  },
  // 餐廳案列
  restaurantCase: {
    bigTitle: '餐厅常遇到的问题及解决案例',
    'Solving Cases': '解决案例',
    'Further understanding': '进一步了解',
    one: {
      label: '人手不足，成本每季增加',
      value:
        '客人利用QR Code点餐系统令餐厅节省接近8-12%待应人手，加上某些餐厅更大大节省了点餐纸的印刷成本。 ',
    },
    two: {
      label: '想与顾客关系更贴近',
      value:
        'APP会员系统，会员可以储分换优惠，餐厅也可以推送优惠劵给顾客，与顾客更多互动及更贴近',
    },
    three: {
      label: '沟通问题，经常落错单',
      value:
        '顾客透过QR code自助点单，减少顾客与待应沟通出错的机会，另外POS系统支援多国语言，减低厨房出错问题',
    },
  },
  products: {
    LearnMore: '了解更多',
    QRCodeOrdering: {
      label: 'QR code点餐',
      value:
        '扫码点餐、固定二维码点餐、预点餐，多种点餐管道，有效提高点餐效率，减少顾客排队的时间，提高了顾客的用餐体验',
    },
    MembershipSystem: {
      label: '会员系统',
      value:
        '线上发布会员卡，方便顾客领取和储存，发展长期，稳定，忠诚的消费群体，扩大市场占有率，新增客户粘性',
    },
    DeliverySelfPickup: {
      label: '外卖/自取',
      value:
        '线上订餐，方便快捷，支持APP线上订餐，顾客可以在家，在公司点餐，餐厅自行配送到顾客所在的位置',
    },
    OnlineBooking: {
      label: '网上订座',
      value: '光速订座APP预订座位，准确记录顾客预订的需求，让服务零距离接触',
    },
    QueueUpToCollectTickets: {
      label: '排队取票',
      value:
        '远程取号无需等位，顾客用APP在任何地方远程取号，自动提醒顾客准备入场',
    },
  },
  //联络我们
  contact: {
    bigTitle: '联络我们 – 姜软件有限公司',
    'Dietary literature, published on Ricepon': '搵饮食着数，上Ricepon',
    'Contact Us': '联络我们',
    'Search for different food and beverage discounts for you':
      '为你搜尽不同餐饮优惠',
    'Search for the latest restaurant information for you':
      '为你搜罗最新餐厅资讯',
    'Most Collected Restaurant Offers': '集齐最多餐厅优惠',
    'Scan Code Download': '扫码下载',
    'Join the ricepon': '加入饭团',
    'Please enter your name': '请输入您的姓名',
    'Please enter your contact number': '请输入您的联络电话',
    'Please enter the correct phone number': '请输入正确的电话号码',
    'Please enter your restaurant name': '请输入您的餐厅名称',
    'Submit application': '提交申请',
    'Leave information': '请填写你的资料，我们很快会有专人与你联络',
    'full name': '姓名',
    Telephone: '联络电话',
    'Restaurant Name': '餐厅名称',
    Notes: '备注',
    'Please enter the information you need to make a note of':
      '请输入您需要备注的信息',
    Cancel: '取消',
    Confirm: '提交',
    Thank: '谢谢,已发送您的资讯',
    Error: '发生错误,请检查您的资料信息',
  },
  // 产品页
  productsPage: {
    bigTitle: '产品',
    title: '产品 – 饭团有限公司',
    btn: '了解产品',
    labelA: {
      title: 'GSPOS 点餐系统',
      desc: '集合多功能于一身，简易操作界面，助你轻松管理你的餐饮业',
    },
    labelB: {
      title: 'GSA 手机点餐系统',
      desc: '随身的移动点餐系统，快捷，方便，准确',
    },
    labelC: {
      title: 'GSQ 自助排队系统',
      desc: '自助取票，广告，叫号，一体化界面，轻松提高餐厅形象',
    },
  },
  case: {
    title: '客户案例 – 姜软件有限公司',
    bigTitle: '客户案例',
    titleA: '热门案例',
    titleB: '我们的客户',
    descA: '饮食集团',
    descB: '泰国菜',
    labelA: '别府饮食集团',
    labelB: '大喜屋',
    labelC: '黄珍珍泰国菜馆',
  },
  // GSPOS 落單系統
  gspos: {
    bigTitle: 'GSPOS 落单系统',
    labelA: {
      title: 'GSPOS 点餐系统功能特点',
      itemA: '多国语言厨房打印',
      itemB: '多国语言餐牌显示',
      itemC: '多重厨房打印设定',
      itemD: '食品同时打印至多台打印机',
      itemF: '厨房飞单功能',
      itemG: '同时多机联网操作',
      itemH: '套餐、食品、细项关系绑定',
      itemI: '时段及假日餐牌设定',
      itemJ: '时段及假日折扣设定',
      itemK: '食品同时打印至多台打印机',
      itemM: '打印总单',
      itemN: '中央餐牌管理',
      itemO: '每日清机结算提醒，及排程自动清机结算',
      itemQ: '自定分类报表',
      itemR: '即时报表',
      itemS: '过去报表及统计分析报表',
    },
    labelB: {
      title: '提供规划建议',
      itemA:
        '针对餐厅的需求，为您的餐厅提供合适的方案，例如：提供整套系统的网络路线规划建议，到餐厅实地视察后与装修师傅商议拉线规划事宜等等。',
    },
    labelC: {
      title: '中央打印管理',
      itemA:
        '以中央打印服务器管理所有打印机的打印工作，不同的食品或饮品可以分类到不同的部门位置进行打印。',
    },
    labelD: {
      title: '后备打印方案',
      itemA:
        '预先设定打印机的后备打印方案，当打印机发生故障或特定时间内没有纸张等情况时，系统会自动将相关单据转发到后备打印机的位置进行打印，我们称之为“飞单”，使餐厅能够正常运作。',
    },
  },
  // GSA 手機點餐系統
  gsa: {
    bigTitle: 'GSA 手机点餐系统',
    labelA: {
      title: 'GSA 手机点餐系统功能特点',
      itemA: '简易操作，容易上手',
      itemB: '支持多国语言',
      itemC: '餐牌更新提醒',
      itemD: '支持印单、打印上菜纸等功能',
      itemE: '简单的滑动操作，使屏幕可以显示最多的内容',
      itemF: '点餐界面排版灵活设置',
      itemG: '转台、连台、分台',
      itemH: '系统一键升级',
    },
    labelB: {
      title: '直接落单点餐',
      itemA:
        '当您为客人点餐时，若配备了GSA手机点餐系统，员工便可以直接使用手机为客人点餐，不用"写纸头"，更不需要每次都前往POS系统主机进行点餐程序，省时方便，有效率地完成点餐程序，增加翻台次数。',
    },
    labelC: {
      title: '减低出错机会',
      itemA:
        '传统"写纸头"点餐，由于很多时由楼面员工负责"写纸头"点餐，再交由特定员工于POS点餐，常常会遇到看不清楚纸头上写的是什么字，或楼面员工写错单，增加不少出错机会，而使用手机系统点餐，可以即时从手机上看到点餐的列表，还可以在客人点餐完成后，即时向客人复述点餐内容，将出错率减到最低。',
    },
  },
  // GSQ 自助排隊系統
  gsq: {
    bigTitle: 'GSQ 自助排队系统',
    labelA: {
      title: '自助取票端',
      itemA: '客人自助输入人数取票',
      itemB: '系统自动分队',
      itemC: '入座SMS短信提示功能',
      itemD: '自定SMS短信发送间距',
      itemE: '输入特定以上人数特别提示',
      itemF: '锁屏功能（防止客人在非营业时间内取票）',
    },
    labelB: {
      title: '号码 / 广告显示端',
      itemA: '界面排版灵活',
      itemB: '支持多个广告面板',
      itemC: '列队模式，多号模式',
      itemD: '支持多屏不同排版',
      itemE: '支持图片、影片、文字播放',
      itemF: '独立设置不同时段及假期的广告播放',
    },
    labelC: {
      title: '叫号端',
      itemA: '简洁界面，操作简单',
      itemB: '支持员工取票功能',
      itemC: '系统自动选取叫号号码，一键叫号',
      itemD: '提供入座数据报表',
    },
  },
  //
  about: {
    bigTitle: 'Ricepon 够姜软件',
    title: '关于Ricepon',
    labelA: {
      title: '公司简介',
      itemA:
        'Ricepon 姜软件在香港成立于2012年，一直专注于餐饮业营运系统上，积极研发及推广全面的餐饮系统软件，致力为客户提供优质的产品与服务，针对客户不同的需求，提供最合适的方案。',
      itemB:
        '我们在深圳拥有自己的开发团队，年轻的成员们都充满着工作热诚，亦十分重视团队的合作精神，用心创造每一个可能。',
      itemBRiceponCn:
        '我们在深圳成立了够姜网络科技有限公司，拥有自己的开发团队，年轻的成员们都充满着工作热诚，亦十分重视团队的合作精神，用心创造每一个可能。',
    },
    labelB: {
      title: '经营理念',
      itemA:
        '餐饮业面对租金高、人工高、食材高、利润低的3高1低痛点，战场方面更由实体店延伸到线上战场，餐厅营运者更需要在激烈的战局中提升竞争力，为解决市场痛点，我们Ricepon的产品设计师多年研发在线上线下解决方案，运用线上系统与线下系统无缝对接，系统一体化令用户与餐厅用最简单的方式沟通，系统智能推荐令餐厅人员能够提前了解客人需要，作出针对性销售，亦可分析餐厅营运布局，作出餐厅资源共享，令进一步提升产能！',
    },
    labelC: {
      itemA: '系统碎片化 vs 系统一体化',
      itemB: '线上线下结合的用户体验',
      itemC: '功能服务链一脉相连',
      itemD: '智慧系统智能推荐',
    },
    labelD: {
      title: 'Ricepon格言',
      desc: '用研发人员的复杂，成就用户的简单！',
    },
  },
  qrcode: {
    bigTitle: 'QR Code点餐',
    desc: '省时方便，减低落错单机会',
    labelA: {
      title: '',
      itemA: 'APP – 支持免注册点餐',
      itemB: 'APP – 扫一下马上点餐',
      itemC: 'APP – 支持一桌多机',
      itemD: 'APP – 离座信息提醒',
      itemE: 'APP – QR指定餐牌',
      itemF: 'APP – 食品数量上限控制',
      itemG: 'POS – 实时沽清控制',
      itemH: 'POS – 时段打印分流',
      itemI: 'POS – 食品项目汇合打印 厨房单 – 头单提醒',
      itemJ: '厨房单 – Last order 提醒 QR Code 一码多用',
      itemK: '(下载APP/点菜/加入会员卡) 餐牌公告提醒',
    },
  },
  vip: {
    bigTitle: '会员系统',
    desc: '增强主动营销能力，与顾客更多互动',
    labelA: {
      title: '',
      itemA:
        '在线发布会员卡，方便顾客领取和储存，发展长期，稳定，忠诚的消费群体，扩大市场占有率，新增客户粘性',
    },
    labelB: {
      title: '餐厅最新信息',
      itemA: '自由编辑宣传内容',
      itemB: '外部连接',
      itemC: '播放短片',
      itemD: '首页限时免费广告',
    },
    labelC: {
      title: '自订投票活动',
      itemA: '品牌自定义投票',
      itemB: '分店自定义投票',
      itemC: '单项投票',
      itemD: '顺位投票',
    },
    labelD: {
      title: '餐厅评论',
      itemA: '订单评论',
      itemB: '菜式评论',
    },
  },
  takeOutOrPickUp: {
    bigTitle: '外卖/自取',
    desc: '先落单后取餐，省时方便',
    labelA: {
      title: '外卖',
      itemA: '在线订餐，方便快捷支持',
      itemB: 'APP在线订餐',
      itemC: '顾客可以在家，在公司点餐',
      itemD: '餐厅自行配送到顾客所在的位置',
    },
  },
  reservation: {
    bigTitle: '网上订座',
    desc: 'Ricepon App带来全新的体验',
    labelA: {
      title: '',
      itemA: '光速订座APP预订座位，准确记录顾客预订的需求，让服务零距离接触',
    },
    labelB: {
      title: '订座入口',
      itemA: '餐厅官网',
    },
    labelC: {
      title: 'Ipad系统功能特点',
      itemA: '坐位图及顾客信息，一览无遗',
      itemB: '统自动SMS确认订位',
      itemC: '时段订位人数限制，控位一手掌握',
      itemD: '支持手机版订座管理',
      itemE: '直接传送客人资料到线下POS',
    },
  },
  lineUp: {
    bigTitle: '排队取票',
    desc: '自助取票方便快捷',
    labelA: {
      title: '',
      itemA: '远程取号无需等位',
      itemB: '支持APP远程取号',
      itemC: '顾客可以在家、在公司等任何地方排队取号自动提醒顾客准备入场',
    },
  },
  biefu: {
    bigTitle: '热门案例',
    title: '别府饮食集团',
    labelA: {
      title: '餐厅面对的问题',
      itemA: '人手填写会员资料，实体卡损耗及缺乏主动营销渠道',
    },
    labelB: {
      title: '解决方案',
      itemA: '产品名称：别府集团APP',
      itemB: '使用别府集团手机APP，SMS验证，会员简易注册，与POS对接',
      itemC: '消费，储经验值，升级，派优惠券，储分，无纸化，以及二次营销',
    },
  },
  daxiwu: {
    bigTitle: '热门案例',
    title: '大喜屋集团',
    labelA: {
      title: '餐厅面对的问题',
      itemA: '人手不足，经常落错单，放题纸印刷成本高昂',
    },
    labelB: {
      title: '解决方案',
      itemA: '产品名称：RICEPON SKYORDER服务',
      itemB: '项目年份：2017',
      itemC: '顾客使用手机APP落单，节省员工落单时间，缩短出餐时间',
      itemD: '减低出错，环保同时更省却放题纸高昂的成本',
    },
  },
  huangzhengzheng: {
    bigTitle: '热门案例',
    title: '黄珍珍泰国菜馆',
    labelA: {
      title: '餐厅面对的问题',
      itemA: '没有自己的会员系统，不能直接给顾客送赠礼物及优惠券',
      itemB: '泰国厨师经常落错单',
    },
    labelB: {
      title: '解决方案',
      itemA: '产品名称：POS + Mobile POS',
      itemB: '项目年份：2018',
      itemC: '店员用Mobile POS落单，节省员工落单时间，减低出错',
      itemD: '厨房同时打印泰文及中文，方便泰国厨师及楼面人员沟通',
    },
  },
};
export default zh;
