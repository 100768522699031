/*
 * @Author: lbh
 * @Date: 2021-04-21 11:08:48
 * @LastEditors: lbh
 * @LastEditTime: 2022-07-20 12:26:35
 * @Description: file content
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import util from '@/js/utils/util';

Vue.use(VueRouter);

let home = () => import('../views/gingersoft/home.vue');
let contact = () => import('../views/gingersoft/contact.vue');
// 更改主頁
if (util.isRicepon()) {
  home = () => import('../views/home.vue');
  contact = () => import('../views/contact_.vue');
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: home, //() => import('../views/home.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact, // () => import('../views/contact.vue'),
  },
  {
    path: '/ricepon',
    name: 'ricepon',
    component: () => import('../views/ricepon.vue'),
  },
  {
    path: '/qrcode',
    name: '/qrcode',
    component: () => import('../views/ricepon/qrcode.vue'),
  },
  {
    path: '/vip',
    name: '/vip',
    component: () => import('../views/ricepon/vip.vue'),
  },
  {
    path: '/takeOutOrPickUp',
    name: '/takeOutOrPickUp',
    component: () => import('../views/ricepon/takeOutOrPickUp.vue'),
  },
  {
    path: '/reservation',
    name: '/reservation',
    component: () => import('../views/ricepon/reservation.vue'),
  },
  {
    path: '/lineUp',
    name: '/lineUp',
    component: () => import('../views/ricepon/lineUp.vue'),
  },
  {
    path: '/game',
    name: '/game',
    component: () => import('../views/ricepon/game.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/products.vue'),
  },
  {
    path: '/gspos',
    name: '/gspos',
    component: () => import('../views/products/gspos.vue'),
  },
  {
    path: '/gsa',
    name: '/gsa',
    component: () => import('../views/products/gsa.vue'),
  },
  {
    path: '/gsq',
    name: '/gsq',
    component: () => import('../views/products/gsq.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue'),
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case.vue'),
  },
  {
    path: '/daxiwu',
    name: 'daxiwu',
    component: () => import('../views/case/daxiwu.vue'),
  },
  {
    path: '/biefu',
    name: 'biefu',
    component: () => import('../views/case/biefu.vue'),
  },
  {
    path: '/huangzhengzheng',
    name: 'huangzhengzheng',
    component: () => import('../views/case/huangzhengzheng.vue'),
  },
  {
    path: '/freeTrial',
    name: 'freeTrial',
    component: () => import('../views/freeTrial.vue'),
  },
  {
    path: '/beppu/download',
    name: 'beppu',
    component: () => import('../views/download/beppu.vue'),
  },
  // gingersoft
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/gingersoft/home.vue'),
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/gingersoft/business.vue'),
  },
  {
    path: '/deliverySystem',
    name: 'deliverySystem',
    component: () => import('../views/deliverySystem.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
